import 'material-symbols';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import homeTop from '@app/static/images/homeTop.jpg';
import homeTopDesktop from '@app/static/images/homeTopDesktop.jpg';
import logo from '@app/static/images/logo.svg';
import orderDetailTop from '@app/static/images/orderDetailTop.jpg';

export const imageAssets = {
  homeTop,
  homeTopDesktop,
  logo,
  orderDetailTop,
};

const palette = {
  black: {
    contrastText: '#fff',
    main: '#000',
  },
  error: {
    contrastText: '#fff',
    main: '#ee6363',
  },
  info: {
    contrastText: '#fff',
    main: '#039c9f',
  },
  neutral: {
    contrastText: '#000',
    greyDark: '#8890a0',
    greyLight: '#f8f8f9',
    main: '#dbdfe5',
    silver: '#e8e8e8',
    white: '#fff',
  },
  orange: {
    contrastText: '#fff',
    main: '#ff8652',
  },
  primary: {
    contrastText: '#fff',
    main: '#CA6172',
  },
  purple: {
    contrastText: '#fff',
    main: '#6a46d1',
  },
  secondary: {
    contrastText: '#000',
    main: '#DBDFE5',
  },
  text: {
    black: '#2F2F2F',
    primary: '#2F2F2F',
    secondary: '#B7BCC6',
    white: '#fff',
  },
  validation: {
    required: {
      checkBox: '#f5c7c7',
      common: '#FFE9E9',
    },
  },
};

type CustomNeutralPalette = {
  contrastText: string;
  greyDark: string;
  greyLight: string;
  main: string;
  silver: string;
  white: string;
};

declare module '@mui/material/styles' {
  interface Palette {
    neutral: CustomNeutralPalette;
    orange: Palette['primary'];
    purple: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: CustomNeutralPalette;
    orange?: PaletteOptions['primary'];
    purple?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    neutral: true;
    orange: true;
    purple: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
    neutral: true;
    orange: true;
    purple: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    neutral: true;
  }
}

const internalTheme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '& .MuiCollapse-root': {
            borderBottom: '0.5px solid #dbdfe5',
          },
          '&::before': {
            height: 0,
          },
          boxShadow: 'none',
          marginBottom: '0 !important',
          marginTop: '0 !important',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-content': {
            alignItems: 'center',
            margin: '5px 0',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '5px 0',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            color: '#8890a0',
          },
          '&.Mui-expanded': {
            alignItems: 'center',
            minHeight: 'unset !important',
          },
          borderBottom: '0.5px solid #dbdfe5',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: palette.primary.main,
        },
        root: {
          '&.required': {
            backgroundColor: palette.validation.required.common,
          },
          borderRadius: '8px',
          boxShadow: 'none !important',
          fontWeight: '400',
          minHeight: '40px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { className: 'required' },
          style: {
            '&.Mui-checked': {
              color: palette.validation.required.checkBox,
            },
            color: palette.validation.required.checkBox,
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.required': {
            backgroundColor: palette.validation.required.common,
          },
        },
        sizeSmall: ({ ownerState }) => ({
          borderRadius: '4px',
          fontSize: '0.7rem !important',
          fontWeight: 600,
          ...(ownerState.color === 'primary'
            ? {
                backgroundColor: palette.primary.main,
              }
            : {}),
          '& .MuiChip-icon': {
            fontSize: 'small',
          },
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingBottom: '24px !important',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: palette.text.primary,
          mozOsxFontSmoothing: 'grayscale',
          webkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.AppCheckboxListItem': {
            '& .MuiListItemIcon-root': {
              minWidth: 'unset',
            },
            borderBottom: '0.5px solid #dbdfe5',
            fontSize: '1rem',
            padding: '0.2em 0',
          },
        },
      },
    },
    MuiRadio: {
      variants: [
        {
          props: { className: 'required' },
          style: {
            '&.Mui-checked': {
              color: palette.validation.required.checkBox,
            },
            color: palette.validation.required.checkBox,
          },
        },
      ],
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: '#f4d116',
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { required: true },
          style: {
            backgroundColor: palette.validation.required.common,
          },
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          minWidth: '85px',
          padding: '12px 4px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          borderBottom: '0.5px solid #dbdfe5',
          borderTop: 'unset',
          fontSize: '1rem',
        },
        head: {
          borderBottom: '0.5px solid #dbdfe5',
          borderTop: '0.5px solid #dbdfe5',
          paddingBottom: '0.65rem !important',
          paddingTop: '0.65rem !important',
          textAlign: 'center',
        },
        sizeMedium: {
          paddingBottom: '1.65rem',
          paddingTop: '1.65rem',
        },
        sizeSmall: {
          fontSize: '0.875rem',
          paddingBottom: '0.75rem',
          paddingTop: '0.75rem',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f8f8f9',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.required input': {
            backgroundColor: palette.validation.required.common,
          },
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
  },
  palette,
  shadows: {
    ...createTheme().shadows,
    1: `0px 2px 2px ${palette.secondary.main}`,
  },
  typography: {
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    body3: {
      fontSize: '0.75rem',
    },
    fontFamily: [
      '"M PLUS 1"',
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      'Arial',
      '"Meiryo"',
      'sans-serif',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Apple Color Emoji"',
    ].join(','),
    fontSize: 16,
    h5: {
      fontSize: '1.5rem',
    },
  },
});

export const theme = responsiveFontSizes(internalTheme);
